import React, { FunctionComponent } from "react";
import { twMerge } from "tailwind-merge";

type ButtonProps = {
  variant?: "primary" | "secondary";
  text: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
  onClick?: () => void;
};

const Button: FunctionComponent<ButtonProps> = ({
  text,
  variant = "primary",
  type,
  disabled,
  loading,
  onClick,
  loadingText = "Loading...",
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={twMerge(
        "cursor-pointer disabled:cursor-default ml-auto w-full md:w-32 flex items-center justify-center py-3 text-base font-medium rounded-md ",
        variant === "primary"
          ? "text-white bg-indigo-600 hover:bg-indigo-700 hover:enabled:bg-indigo-600 disabled:bg-indigo-400 disabled:cursor-not-allowed"
          : "text-indigo-700 bg-indigo-100 hover:bg-indigo-200 disabled:bg-indigo-100 disabled:cursor-not-allowed",
      )}
      disabled={disabled}
    >
      {loading ? <span className="animate-pulse">{loadingText}</span> : text}
    </button>
  );
};

export default Button;
